@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply  border border-black rounded-xl px-5 py-1
  }
}

body{
  font-family: 'Kalam', cursive;
}

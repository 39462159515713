/* grid.css */

/* General table styles */
.table-auto {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .table-auto {
      width: 100%;
      overflow-x: auto;
      display: block;
    }
  
    .table-auto thead {
      display: none;
    }
  
    .table-auto tbody,
    .table-auto tr {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  
    .table-auto td {
      display: block;
      text-align: right;
      font-size: 0.9rem;
      padding: 10px;
      position: relative;
      border: none;
    }
  
    .table-auto td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 10px;
      font-weight: bold;
      text-align: left;
      background: #f9f9f9;
      top: 0;
      bottom: 0;
      line-height: 1.5;
    }
  }
  